<template>
  <v-app>
    <v-app-bar
      class="px-3 pt-6"
      color="primary"
      flat
      app
      floating
      extended
    >
      <v-container class="mx-auto white--text">
        <v-row>
          <v-toolbar-title tag="v-btn" class="pr-16 text-h4">DR ADRIAN FALKOV</v-toolbar-title>
          
          <v-spacer></v-spacer>
          <div class="">
            <v-btn
              text
              to="/"
              color="white"
            >
              Home
            </v-btn>
            
            <v-btn
              text
              to="about"
              color="white"
            >
              About
            </v-btn>
            
            <v-btn
              text
              to="services"
              color="white"
            >
              The Service
            </v-btn>

            <v-btn
              text
              to="appointments"
              color="white"
            >
              Book an appointment
            </v-btn>

            <!-- <v-btn
              text
              to="clinical-initiatives"
              color="white"
            >
              Clinical initiatives & resources
            </v-btn> -->
          </div>

          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main class="bg-grey-lighten-3 mb-11">
      <v-container fluid class="mb-6">
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app padless absolute color="primary" class="white--text">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <div class="d-flex align-center">
              <v-icon class="mr-2">mdi-map-marker</v-icon>
              <span>4/181 High Street, Willoughby NSW 2068</span>
            </div>
            <div class="d-flex align-center">
              <v-icon class="mr-2">mdi-phone</v-icon>
              <span>0417 420 664</span>
            </div>
            <div class="d-flex align-center">
              <v-icon class="mr-2">mdi-email</v-icon>
              <span>adrianfalkov@bigpond.com</span>
            </div>
          </v-col>
          <v-col class="d-flex justify-center align-center">
            <v-img src="@/assets/FRANZCP_Insignia.png" max-width="200px" max-height="80"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
  }),

  metaInfo: {
    script: [
      { innerHTML: '{ "@context": "http://www.schema.org", "@type": "Organization" }', type: 'application/ld+json' },
      { innerHTML: '{ "body": "yes" }', body: true, type: 'application/ld+json' }
    ],
    //__dangerouslyDisableSanitizers: ['script']
  }
};
</script>

<style>
.nav-link {
  text-color: white;
  font-size: 1.2rem;
}

</style>
