<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-carousel cycle hide-delimiter-background>
          <v-carousel-item v-for="(item, i) in carouselItems" :key="i" eager reverse-transition="expand-transition" transition="expand-transition">
            <v-img :src="item.img" aspect-ratio="1.5" height="100%"></v-img>
            <v-sheet
              class="carousel-overlay"
              :color="`rgba(0, 0, 0, ${item.overlayOpacity || 0.7})`"
            >
              <div class="carousel-text">
                <h2>{{ item.title }}</h2>
                <p>{{ item.subtitle }}</p>
                <v-btn color="primary" :href="item.btnLink">{{ item.btnText }}</v-btn>
              </div>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>

    <v-row>
      <!-- <v-col cols="12" md="4">
        <v-card class="pa-4" outlined>
          <v-icon size="64" color="primary">mdi-account-group</v-icon>
          <h2>Our Team</h2>
          <p>
            Our team of medical professionals is dedicated to providing compassionate and comprehensive care to all of our patients.
          </p>
          <v-btn color="primary" href="/about">Meet Our Team</v-btn>
        </v-card>
      </v-col> -->
      <v-col cols="12" md="6">
        <v-card class="pa-4" outlined height="100%">
          <v-icon size="64" color="primary">mdi-doctor</v-icon>
          <h2>The Service</h2>
          <p>
            Adrian provides a range of options to meet your and your family’s mental health needs.
          </p>
          <v-btn color="primary" href="/services" class="mt-6">Learn More</v-btn>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="pa-4" outlined>
          <v-icon size="64" color="primary">mdi-calendar-check</v-icon>
          <h2>To Book an Appointment</h2>
          <p>
            Fill out the registration form.
            You will be contacted within 48 hrs to discuss your needs.
            If you require urgent assistance please call your GP or attend your local Emergency Department.
          </p>
          <v-btn color="primary" href="/appointments">Book Now</v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="12" md="12" lg="6">
        <v-card class="pa-4" outlined>
           <iframe width="100%" height="463.5" src="https://www.youtube.com/embed/znlweGrtbeI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> 
        </v-card>
      </v-col>

      <v-col cols="12" md="12" lg="6">
        <v-card class="pa-4" outlined>
          <GmapMap
            :center='center'
            :zoom='16'
            style='width:100%;  height: 470px;'
          >
            <GmapMarker
              :position='center'
            />
          </GmapMap>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HelloWorld',

  data() {
    return {
      carouselItems: [
        {
          img: require('@/assets/DRAF_2.jpeg'),
          title: 'Welcome to the Clinic',
          subtitle: 'Sharing expertise and lived experience for better mental health',
          btnText: 'Learn More',
          btnLink: '/about',
          overlayOpacity: 0.5,
        },
        {
          img: require('@/assets/street_view2.jpg'),
          title: 'Book an Appointment',
          subtitle: 'Please complete the application form and you will be contacted within 48 hours',
          btnText: 'Book Now',
          btnLink: '/appointments',
          overlayOpacity: 0.5,
        },
        {
          img: require('@/assets/DRAF_group.jpeg'),
          title: 'The Service',
          subtitle: 'Adrian provides a range of options to meet your and your family’s mental health needs',
          btnText: 'Learn More',
          btnLink: '/services',
          overlayOpacity: 0.5,
        }],

      testimonials: [
        {
          text: 'I have been a patient at this clinic for years and have always received excellent care from the entire staff. Highly recommended!',
          author: 'Jane Doe',
        },
        {
          text: 'I was nervous about my procedure, but the doctor and nurses at this clinic made me feel comfortable and cared for throughout the entire process.',
          author: 'John Smith',
        },
        {
          text: 'The team at this clinic went above and beyond to help me navigate a complicated health issue. I am so grateful for their support and expertise.',
          author: 'Sara Johnson',
        }],

      center: { lat: -33.796541636025886, lng: 151.20119611349423 },
    };
  },
  created() {
    require('@/assets/DRAF_group.jpeg')
    require('@/assets/street_view2.jpg')
    require('@/assets/DRAF_2.jpeg')
  }
};
</script>

<style scoped>
.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.carousel-text {
  color: #fff;
  text-align: center;
}

.primary {
}

h2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
}
</style>

