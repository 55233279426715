<template>
  <about-page />
</template>

<script>
  import AboutPage from '../components/AboutPage'

  export default {
    name: 'AboutView',

    components: {
      AboutPage,
    },
    metaInfo: {
      title: 'About',
      titleTemplate: '%s | Dr Adrian Falkov Psychiatrist',
      keywords: 'Dr Adrian Falkov,Child & youth psychiatrist'
    }
  }
</script>
