<template>
  <AppointmentRequestForm/>
</template>

<script>
  import AppointmentRequestForm from '../components/AppointmentRequestForm'

  export default {
    name: 'AppointmentsView',

    components: {
      AppointmentRequestForm,
    },
    metaInfo: {
      title: 'Appointments',
      titleTemplate: '%s | Dr Adrian Falkov Psychiatrist',
      keywords: 'Dr Adrian Falkov'
    }
  }
</script>
