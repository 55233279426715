<template>
  <home-page />
</template>

<script>
  import HomePage from '../components/HomePage'

  export default {
    name: 'HomeView',

    components: {
      HomePage,
    },
    metaInfo: {
      title: 'Home',
      titleTemplate: '%s | Dr Adrian Falkov Psychiatrist'
    }
  }
</script>
