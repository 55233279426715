<template>
  <div class="services-page">
    <v-container>
      <h1 class="page-title">The Service</h1>
      <v-row justify="center">
        <v-col cols="12" class="">
          <p class="lead">Adrian’s approach is to undertake a comprehensive assessment for diagnostic
          purposes to inform treatment options. Treatment is supported by a Care Plan, based
          on past experiences and treatment(s), reports and communication with other
          professionals where relevant, including your GP.</p>

          <p class="lead">This approach facilitates integration of evidence-informed psychological,
          pharmacological, family and lifestyle interventions to promote recovery and relapse
          prevention.</p>

          <p class="lead">Clinical interests and expertise include:
            <ul style="columns:2;">
              <li>Mood disorders</li>
              <li>Anxiety</li>
              <li>OCD</li>
              <li>Self-harm & suicide</li>
              <li>Trauma loss & stress</li>
              <li>School avoidance and bullying</li>
              <li>ADHD</li>
              <li>Chronic fatigue and psychosomatic
                  illnesses</li>
              <li>Interactions between psychological
                  symptoms, parenting and
                  attachment relationships</li>
              <li>Developmental behaviour &
                  emotion regulation difficulties</li>
              <li>Early psychosis</li>
              <li>Psychological consequences of
                  chronic physical illnesses (esp
                  diabetes and asthma)</li>
            </ul>
          </p>

          <p class="lead">The interplay between an individual’s strengths and struggles is an important
          determinant of health outcomes, as is the quality of their key relationships including
          family members and friendships. These elements will be actively addressed in
          treatment and can include aspects of parenting (eg parenting stress, parental mental
          health), and educational challenges (eg school readiness; school avoidance; HSC,
          University or TAFE pressures and strategies).</p>

          <p class="lead">Adrian is also able to incorporate innovative approaches into treatment. For example using:
            <ul>
              <li><a href="https://www.thefamilymodel.com" target="_blank">The Family Model</a> to support communication and
                  relationship work. Adrian has extensive experience working with families where 1
                  (or more) individuals experience mental ill-health. This includes children’s
                  experience of parental mental illness, parents experience of children’s health &amp;
                  mental health difficulties and the challenges for all family members when
                  someone is unwell. Best outcomes are achieved when an individual’s (health)
                  needs and the needs of others are acknowledged and incorporated into the care
                  plan.
              </li>
              <li>The <a href="https://safewatch.care/" target="_blank">SafeWatch</a> app (currently in development) to assist with Care Plan
                  development and treatment monitoring. This digital application brings together
                  the core components of holistic clinical treatment and supports implementation of
                  your individualised care plan. It does this by helping you provide systematic
                  feedback at appointments and monitoring your treatment progress between
                  appointments.
              </li>
            </ul>
          </p>

          <p class="lead">Please contact Adrian if you have questions.</p>

          <v-btn
            to="appointments"
            color="primary"
          >
            Book an appointment
          </v-btn>

          <!-- <h2>Appointment duration and type</h2>
          <p class="lead">
          Adrian provides in person and TeleHealth sessions of 30-90 minutes duration based
          on clinical need, age and complexity.</p>
          <p class="lead">For young people under 18 years of age, the initial appointment is with the young
          person and their parent/carer(s) for 90 minutes. Follow-up appointments may be
          required with parents separately or together with their child. This will depend on the
          outcome from the initial assessment. These appointments will be 60 minutes.</p>
          <p class="lead">For patients living independently, initial appointment is with the patient, however if
          the patient wishes Adrian will also see relevant parent/carer together with the
          patient. This will be 60 minutes duration. Follow-up appointments will be 45 minutes.</p>
          <p class="lead">For medication review and prescription renewal, appointments are 30 minutes.</p>

          <h2>Booking an appointment</h2>
          <p class="lead">To book an appointment a referral from a GP or medical specialist is required. This
          referral will assist in the process of establishing/enhancing your treating
          team/network and obtaining the Medicare rebate for those who are
          citizens/permanent residents. The GP referral is valid for 12 months and the
          specialist medical referral for 3 months. Please ensure that your referral is kept up to
          date.</p>

          <h2>Medicare rebate</h2>
          <p class="lead">Please contact the clinic for information about appointment costs, Medicare rebates
          and out of pocket expenses. Note that if the Medicare safety net threshold has been
          reached, Medicare rebate can be up to 95%. For further information visit
          <a href="https://www.humanservices.gov.au/individuals/services/Medicare/Medicare–safety–net" target="_blank">https://www.humanservices.guv.au/individuals/services/Medicare/Medicare–safety–net.</a> This is not a bulk billing clinic.</p>
          <p class="lead">Please note there is no Medicare rebate for out of session prescription fees, reports
          or cancellation fees. If cancellation is necessary, please do this as early as possible
          so that the appointment can be offered to someone on the waitlist.</p>
          <p class="lead">If the cancellation occurs 3 days before the appointment or less, a 50% fee is
          charged. Non-attendance on the day of appointment attracts a 100% fee.
          Cancellation fees do not attract the Medicare rebate.</p> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ServicesPage",
};
</script>

<style scoped>
.page-title {
  margin-top: 3rem;
  margin-bottom: 2rem;
  text-align: center;
}

p.lead {
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 2rem;
}


</style>
