<template>
  <services-page />
</template>

<script>
  import ServicesPage from '../components/ServicesPage'

  export default {
    name: 'ServicesView',

    components: {
      ServicesPage,
    },
    metaInfo: {
      title: 'Services',
      titleTemplate: '%s | Dr Adrian Falkov Psychiatrist',
      keywords: 'Depression,anxiety,bipolar,ocd,ADHD,avoidance,PTSD'
    }
  }
</script>
