<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="display-2 text-center mb-5">New Patient Application Form</h1>
      </v-col>
    </v-row>
    <v-form action="https://script.google.com/macros/s/AKfycbySCgZ53w4Xosoj4usEwimAoVE2KE5fUPxqLVWCMQTADafcwwSUeis8TXfwRRT8nRIt/exec" method="POST" class="gform" ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            id="name"
            name="name"
            label="Name" 
            v-model="name" 
            :rules="[v => !!v || 'Name is required']"
            required
          ></v-text-field>
          
          <v-text-field
            id="dob"
            name="dob"
            label="DOB (day/month/year)" 
            v-model="dob" 
            :rules="dobRules"
            required
          ></v-text-field>
          
          <v-text-field 
            id="age"
            name="age"
            label="Age" 
            v-model="age"
            :rules="[v => !!v || 'Age is required']"
            required
          ></v-text-field>
          
          <v-text-field 
            id="address"
            name="address"
            label="Address" 
            v-model="address"
            :rules="addressRules" 
            required
          ></v-text-field>
          
          <v-text-field 
            id="postcode"
            name="postcode"
            label="Postcode" 
            v-model="postcode" 
            :rules="postcodeRules"
            required
          ></v-text-field>
          
          <v-text-field 
            id="homePhone"
            name="homePhone"
            label="Home Phone" 
            v-model="homePhone"
          ></v-text-field>
          
          <v-text-field 
            id="workPhone"
            name="workPhone"
            label="Work Phone" 
            v-model="workPhone"
          ></v-text-field>
          
          <v-text-field 
            id="mobilePhone"
            name="mobilePhone"
            label="Mobile Phone" 
            v-model="mobilePhone"
            required
          ></v-text-field>
          
          <v-text-field 
            id="email"
            name="email"
            label="Email" 
            v-model="email" 
            :rules="emailRules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field 
            id="occupation"
            name="occupation"
            label="Name of School/University/Occupation" 
            v-model="occupation" 
            required
          ></v-text-field>

          <v-select 
            id="consentType"
            name="consentType"
            :items="consentTypes" 
            label="Consent for < 18yr olds given by" 
            v-model="consentType"
            required
          ></v-select>

          <v-text-field 
            id="keyContact"
            name="keyContact"
            label="Name of key contact if different to pt/client" 
            v-model="keyContact"
          ></v-text-field>

          <v-textarea 
            id="reason"
            name="reason"
            label="Brief summary of key issues & reason for seeking help" 
            v-model="reason" 
            :rules="[v => !!v || 'Required field']"
            required
          ></v-textarea>

          <v-textarea 
            id="professionals"
            name="professionals"
            label="GP & other professional involved (Current and Past)" 
            v-model="professionals"
          ></v-textarea>

          <v-textarea 
            id="risks"
            name="risks"
            label="History of current and/or past risks incl hospital attendance/admissions" 
            v-model="risks"
          ></v-textarea>

          <v-textarea 
            id="conditions"
            name="conditions"
            label="Existing medical conditions" 
            v-model="conditions" 
          ></v-textarea>

          <v-textarea 
            id="medications"
            name="medications"
            label="Current medications" 
            v-model="medications" 
          ></v-textarea>

          <v-select 
            id="vaccine"
            name="vaccine"
            :items="vaccineTypes" 
            label="COVID-19 vaccinations" 
            v-model="vaccine"
            :rules="[v => !!v || 'Item is required']"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-btn type="submit" color="primary" :disabled="!valid" @click="submitForm">Submit</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar app text multi-line v-model="snackbar.show" :timeout="snackbar.timeout" :color="snackbar.color">{{ snackbar.message }}</v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: 'AppointmentRequestForm',
  data() {
    return {
      name: '',
      dob: '',
      age: '',
      address: '',
      postcode: '',
      homePhone: '',
      workPhone: '',
      mobilePhone: '',
      email: '',
      occupation: '',
      consentType: '',
      keyContact: '',
      reason: '',
      professionals: '',
      risks: '',
      conditions: '',
      medications: '',
      vaccine: '',
      valid: false,

      dobRules: [
        v => !!v || 'DOB is required',
        v => /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/.test(v) || 'DOB must be valid'
      ],
      addressRules: [
        v => !!v || 'Address is required',
      ],
      postcodeRules: [
        v => !!v || 'Postcode is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      snackbar: {
        show: false,
        message: '',
        timeout: 5000,
        color: ''
      }
    };
  },
  computed: {
    consentTypes() {
      return ['Parents', 'Guardian', 'Other'];
    },
    vaccineTypes() {
      return ['Single Vax', 'Double Vax', 'Triple Vax', 'Not Vaccinated'];
    },
  },
  created() {
    let smtp = document.createElement('script')
    smtp.setAttribute('src', 'https://smtpjs.com/v3/smtp.js')
    document.head.appendChild(smtp)
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {

        // const formData = {
        //   name: this.name,
        //   dob: this.dob,
        //   age: this.age,
        //   address: this.address,
        //   postcode: this.postcode,
        //   homePhone: this.homePhone,
        //   workPhone: this.workPhone,
        //   mobilePhone: this.mobilePhone,
        //   email: this.email,
        //   occupation: this.occupation,
        //   consentType: this.consentType,
        //   keyContact: this.keyContact,
        //   reason: this.reason,
        //   professionals: this.professionals,
        //   risks: this.risks,
        //   conditions: this.conditions,
        //   medications: this.medications,
        //   vaccine: this.vaccine,
        // };
        // console.log(formData);
        // let emailBody = `
        //   Name: ${formData.name}<br>
        //   DOB: ${formData.dob}<br>
        //   Age: ${formData.age}<br>
        //   Address: ${formData.address}<br>
        //   Postcode: ${formData.postcode}<br>
        //   Home phone: ${formData.homePhone}<br>
        //   Work phone: ${formData.workPhone}<br>
        //   Mobile phone: ${formData.mobilePhone}<br>
        //   Email: ${formData.email}<br>
        //   Occupation: ${formData.occupation}<br>
        //   Consent type: ${formData.consentType}<br>
        //   Key contact: ${formData.keyContact}<br>
        //   Reason: ${formData.reason}<br>
        //   Professionals: ${formData.professionals}<br>
        //   Risks: ${formData.risks}<br>
        //   Conditions: ${formData.conditions}<br>
        //   Medications: ${formData.medications}<br>
        //   Vaccine: ${formData.vaccine}
        //   `

        // window.Email.send({
        //   SecureToken: "ea05e742-ed4e-42a8-891a-012a6ba8f3c6",
        //   To: "adrianfalkov@icloud.com",
        //   From: "aranwit1@hotmail.com",
        //   Subject: "New Patient Request",
        //   Body: emailBody
        // }).then((msg) => {
        //   console.log(msg)
        //   if (msg == "OK") {
        //     this.snackbar.message = 'Form submitted successfully! You will be contacted within 48 hours.';
        //     this.snackbar.color = "success"
        //     this.snackbar.show = true;
        //   }
        //   else {  
        //     this.snackbar.message = 'Form error! Try again later.';
        //     this.snackbar.color = "error"
        //     this.snackbar.show = true;
        //   }
        // });
      }
    },
  },
};
</script>
