<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <!-- <h1 class="display-2 text-center mb-5">About</h1> -->
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card-text class="text-h4 mb-n7">Dr Adrian Falkov</v-card-text>
        <v-card-text class="caption font-weight-light mb-n4">BSc, MBBCH, FRCPsych, FRANZCP</v-card-text>
        <img :src="require('@/assets/DRAF.jpg')" alt="Dr Adrian Falkov" class="img-fluid">
      </v-col>
      <v-col cols="12" md="6">
        <p class="lead">Dr Adrian Falkov has more than 30 years experience in mental health services as a
        child, adolescent and family psychiatrist in public and private practice. This
        experience enables Adrian to offer accessible, specialist assessment and treatment
        tailored to each person’s needs within their family context.</p>

        <p class="lead">Adrian believes strongly in the value of working in partnership. This integrated
        approach to treatment is based on shared expertise – his professional expertise and
        your lived experience expertise. The best outcomes occur when this is shared in
        order to enhance resilience, coping and recovery for the whole family.</p>

        <p class="lead">Adrian has held positions in mental health policy & leadership, carer and consumer
        advocacy, training & consultancy, service development and research in England,
        Europe and Australia. He is a member of a number of international professional
        colleges and collaborative networks, has published in peer reviewed journals and
        has delivered keynote addresses, workshops and seminars at institution meetings,
        national and international conferences.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AboutPage',
};
</script>

<style scoped>
h2 {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

p.lead {
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 2rem;
}
</style>
